
import React, { useState, useEffect } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import Papa from 'papaparse';
import Excel from '../../components/excel/Excel';

const InvestmentPortfolioPage = () => {
  const [npsData, setNPSData] = useState<any[]>([]);
  const [zerodhaData, setZerodhaData] = useState<any>();
  const [nps, setNPS] = useState<number>(0);
  const [zerodha, setZerodha] = useState<number>(0);

  const handleNPSFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setNPSData(result.data);
        },
        header: true, // Set to true if CSV file has a header row
      });
    }
  };

  useEffect(() => {
    npsData.forEach((entry) => {
      const statements = entry["NPS Transaction Statement for Tier I Account"].split(" ");
      if (statements[0] === "Rs") {
        setNPS(statements[1])
      }
    })
    
  }, [npsData])



  const handleZerodhaFileChange = (data: any) => {
    setZerodhaData(data);
  }

  useEffect(() => {
    const combined = zerodhaData?.["Combined"];
    if (combined?.length > 0) {
      const entries: number[] = [];
      combined.forEach((entry: any) => {
        if (Object.keys(entry).length > 0 && typeof entry["undefined"] === "number") {
          entries.push(entry["undefined"])
        }
      });
      setZerodha(Math.max(...entries))
    }
  }, [zerodhaData])
  

  
  
  

  return (
    <Box border={"1px solid yellow"}>
      {/* NPS */}
      <Box>
        <input type="file" accept=".csv" onChange={handleNPSFileChange} />
        <Button onClick={() => console.log(npsData)}>Log Data</Button>
        <Box mt={4}>
          NPS: {nps}
        </Box>
      </Box>
      

      {/* Zerodha */}
      <Box>
        <Excel 
          onFileUpload={handleZerodhaFileChange}
          visibility={"upload"}
        />
        <Box>
          Zerodha: {zerodha}
        </Box>
      </Box>
    </Box>
  );
}

export default InvestmentPortfolioPage