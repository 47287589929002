/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { capitalize } from "lodash";
import TextAccent from "./../../components/text/TextAccent";
import Excel from "./../../components/excel/Excel";

interface IData {
  year: number;
  age: number;
  expenses: number;
  investment: number;
  wealth: number;
}

interface IPayloadItem {
  name: string;
  value: number;
  payload: any;
  color: string;
}

const RetirementCalculatorPage = () => {
  const [age, setAge] = useState<number>(27);
  const [expenses, setExpenses] = useState<number>(600000);
  const [investment, setInvestment] = useState<number>(180000);
  const [data, setData] = useState<IData[]>([]);

  const [lifeExpectancy, setLifeExpectancy] = useState<number>(100);
  const [retirementAge, setRetirementAge] = useState<number>(60);
  const [rateOfInflation, setRateOfInflation] = useState<number>(0.06);
  const [rateOfStepUp, setRateOfStepUp] = useState<number>(0.1);
  const [rateOfActiveInterest, setRateOfActiveInterest] =
    useState<number>(0.12);
  const [rateOfPassiveInterest, setRateOfPassiveInterest] =
    useState<number>(0.06);

  const [isAdvanceOptionsOpened, setIsAdvanceOptionsOpened] =
    useState<boolean>(false);

  // Example usage:
  // const principal = 1000; // Initial amount
  // const rate = 0.05;      // Annual interest rate (5%)
  // const time = 10;        // Time in years
  // const n = 12;           // Number of times interest is compounded per year
  const calculateCompoundInterest = (
    principal: number,
    rate: number,
    time: number,
    n: number = 1
  ) => {
    return principal * Math.pow(1 + rate / n, n * time);
  };

  const calculate = (
    age: number,
    expenses: number,
    investment: number,
    lifeExpectancy: number = 100,
    retirementAge: number = 60,
    rateOfInflation: number = 0.06,
    rateOfStepUp: number = 0.1,
    rateOfActiveInterest: number = 0.12,
    rateOfPassiveInterest: number = 0.06
  ): IData[] => {
    const remaingEarningLife = retirementAge - age;
    const year = new Date().getFullYear();

    const data: IData[] = [];
    for (let index = 0; index <= lifeExpectancy - age; index++) {
      const expensesToBe = calculateCompoundInterest(
        expenses,
        rateOfInflation,
        index
      );
      const investmentToBe = calculateCompoundInterest(
        investment,
        rateOfStepUp,
        index
      );

      if (index <= remaingEarningLife) {
        data.push({
          year: year + index,
          age: age + index,
          expenses: expensesToBe,
          investment: investmentToBe,
          wealth:
            (data[index - 1]?.wealth ?? 0) * (1 + rateOfActiveInterest) +
            investmentToBe,
        });
      } else {
        data.push({
          year: year + index,
          age: age + index,
          expenses: expensesToBe,
          investment: 0,
          wealth:
            ((data[index - 1]?.wealth ?? 0) - expensesToBe) *
            (1 + rateOfPassiveInterest),
        });
      }
    }

    return data;
  };

  const format = (input: number): string => {
    if (input === 0) return "0";
    return input.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const onClickHandler = (): void => {
    const data = calculate(
      age,
      expenses,
      investment,
      lifeExpectancy,
      retirementAge,
      rateOfInflation,
      rateOfStepUp,
      rateOfActiveInterest,
      rateOfPassiveInterest
    );
    setData(data);
    console.log("calculate", data);
  };

  const RenderTooltip = ({ active, payload, label }: any): JSX.Element => {
    if (active && payload && payload.length > 0) {
      return (
        <Box
          border={"1px solid gray"}
          borderRadius={"md"}
          padding={3}
          backgroundColor={"rgba(0,0,0,0.75)"}
          textAlign={"left"}
          fontSize={"0.95rem"}
          lineHeight={"1rem"}
        >
          <Text color={"#fff"}>Age: {label}</Text>
          {payload.map((element: IPayloadItem) => (
            <Text key={element.name} color={element.color}>{`${capitalize(
              element.name
            )}: ${format(element.value)}`}</Text>
          ))}
        </Box>
      );
    }

    return <></>;
  };

  useEffect(() => {
    setData(
      calculate(
        age,
        expenses,
        investment,
        lifeExpectancy,
        retirementAge,
        rateOfInflation,
        rateOfStepUp,
        rateOfActiveInterest,
        rateOfPassiveInterest
      )
    );
  }, [
    age,
    expenses,
    investment,
    lifeExpectancy,
    retirementAge,
    rateOfInflation,
    rateOfStepUp,
    rateOfActiveInterest,
    rateOfPassiveInterest,
  ]);

  //age, expenses, investment
  return (
    <Flex marginY={12} justifyContent={"center"} alignItems={"center"}>
      <Flex width={"100%"} wrap={"wrap"}>
        <Flex width={"40%"} gap={3} wrap={"wrap"} justifyContent={"center"}>
          <Box width={"30%"}>
            <Text>Age</Text>
            <InputGroup alignItems={"baseline"}>
              <Input
                marginTop={1}
                type={"number"}
                placeholder={"Age"}
                value={age}
                onChange={(event) => setAge(Number(event.target.value))}
              />
              <InputRightAddon>years</InputRightAddon>
            </InputGroup>
          </Box>

          <Box width={"30%"}>
            <Text>Yearly expenses</Text>
            <Input
              marginTop={1}
              type={"number"}
              placeholder={"Yearly expenses"}
              value={expenses}
              onChange={(event) => setExpenses(Number(event.target.value))}
            />
          </Box>

          <Box width={"30%"}>
            <Text>Yearly investment</Text>
            <Input
              marginTop={1}
              type={"number"}
              placeholder={"Yearly investment"}
              value={investment}
              onChange={(event) => setInvestment(Number(event.target.value))}
            />
          </Box>

          <TextAccent
            width={"100%"}
            align={"center"}
            fontSize={"0.95rem"}
            _hover={{
              cursor: "pointer",
            }}
            onClick={() => setIsAdvanceOptionsOpened(!isAdvanceOptionsOpened)}
          >
            Advance options
          </TextAccent>

          {isAdvanceOptionsOpened && (
            <>
              <Box width={"30%"}>
                <Text>Life expectancy</Text>
                <InputGroup alignItems={"baseline"}>
                  <Input
                    marginTop={1}
                    type={"number"}
                    placeholder={"Life expectancy"}
                    value={lifeExpectancy}
                    onChange={(event) =>
                      setLifeExpectancy(Number(event.target.value))
                    }
                  />
                  <InputRightAddon>years</InputRightAddon>
                </InputGroup>
              </Box>

              <Box width={"30%"}>
                <Text>Retirement age</Text>
                <InputGroup alignItems={"baseline"}>
                  <Input
                    marginTop={1}
                    type={"number"}
                    placeholder={"Retirement age"}
                    value={retirementAge}
                    onChange={(event) =>
                      setRetirementAge(Number(event.target.value))
                    }
                  />
                  <InputRightAddon>years</InputRightAddon>
                </InputGroup>
              </Box>

              <Box width={"30%"}>
                <Text>Rate of inflation</Text>
                <InputGroup alignItems={"baseline"}>
                  <Input
                    marginTop={1}
                    type={"number"}
                    placeholder={"Rate of inflation"}
                    value={(rateOfInflation * 100).toFixed(2)}
                    onChange={(event) =>
                      setRateOfInflation(Number(event.target.value) / 100)
                    }
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </Box>

              <Box width={"30%"}>
                <Text>Rate of step up</Text>
                <InputGroup alignItems={"baseline"}>
                  <Input
                    marginTop={1}
                    type={"number"}
                    placeholder={"Rate of step up"}
                    value={(rateOfStepUp * 100).toFixed(2)}
                    onChange={(event) =>
                      setRateOfStepUp(Number(event.target.value) / 100)
                    }
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </Box>

              <Box width={"30%"}>
                <Text>Rate of active interest</Text>
                <InputGroup alignItems={"baseline"}>
                  <Input
                    marginTop={1}
                    type={"number"}
                    placeholder={"Rate of active interest"}
                    value={(rateOfActiveInterest * 100).toFixed(2)}
                    onChange={(event) =>
                      setRateOfActiveInterest(Number(event.target.value) / 100)
                    }
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </Box>

              <Box width={"30%"}>
                <Text>Rate of passive interest</Text>
                <InputGroup alignItems={"baseline"}>
                  <Input
                    marginTop={1}
                    type={"number"}
                    placeholder={"Rate of passive interest"}
                    value={(rateOfPassiveInterest * 100).toFixed(2)}
                    onChange={(event) =>
                      setRateOfPassiveInterest(Number(event.target.value) / 100)
                    }
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </Box>
            </>
          )}

          <Button onClick={onClickHandler}>Calculate</Button>
        </Flex>

        {data.length > 0 && (
          <Box width={"60%"}>
            {/* <Divider marginY={6} /> */}

            <Tabs align="center" variant="soft-rounded" colorScheme="teal">
              <TabList>
                <Tab borderRadius={"md"}>Table</Tab>
                <Tab borderRadius={"md"}>Chart</Tab>
              </TabList>
              <Flex
                width={"full"}
                marginTop={6}
                justifyContent={"center"}
                alignItems={"center"}
                gap={6}
              >
                <Text>Returement calculation</Text>
                <Excel
                  name={"Returement calculation"}
                  data={{
                    "Retirement calculation": data as unknown as {
                      [key: string]: string | number;
                    }[],
                  }}
                  visibility={"download"}
                />
              </Flex>
              <TabPanels>
                <TabPanel>
                  <Box width={"100%"} height={500} overflowY={"auto"}>
                    <TableContainer>
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            <Th>Year</Th>
                            <Th>Age</Th>
                            <Th isNumeric={true}>Expenses</Th>
                            <Th isNumeric={true}>Investment</Th>
                            <Th isNumeric={true}>Wealth</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data.map((entry) => (
                            <Tr
                              borderBottomWidth={
                                entry.age === retirementAge ? 3 : 1
                              }
                            >
                              <Td>{entry.year}</Td>
                              <Td>{entry.age}</Td>
                              <Td isNumeric={true}>{format(entry.expenses)}</Td>
                              <Td isNumeric={true}>
                                {format(entry.investment)}
                              </Td>
                              <Td isNumeric={true}>{format(entry.wealth)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>

                <TabPanel>
                  <Box width={"100%"} height={500}>
                    <ResponsiveContainer width="100%" height="100%">
                      <ComposedChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 10,
                          bottom: 10,
                          left: 10,
                        }}
                      >
                        <Tooltip content={RenderTooltip} />

                        <XAxis
                          dataKey={"age"}
                          label={{ value: "Age", position: "insideBottom" }}
                          height={50}
                        />
                        <YAxis
                          label={{
                            value: "Amount",
                            angle: -90,
                            position: "insideLeft",
                          }}
                          width={150}
                          // tick={{angle: 45}}
                        />

                        <Line
                          dot={false}
                          dataKey={"expenses"}
                          stroke="#00B0FF"
                        />
                        <Line
                          dot={false}
                          dataKey={"investment"}
                          stroke="#00BFA6"
                        />
                        <Line dot={false} dataKey={"wealth"} stroke="#F9A826" />
                      </ComposedChart>
                    </ResponsiveContainer>
                    <Flex
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={3}
                      paddingLeft={125}
                    >
                      <Text color={"#00B0FF"}>Expenses</Text>
                      <Text color={"#00BFA6"}>Investment</Text>
                      <Text color={"#F9A826"}>Wealth</Text>
                    </Flex>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default RetirementCalculatorPage;
