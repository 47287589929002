import AppRoutes from "./routes/AppRoutes";
import Providers from "./providers/Providers";

export default function App(): JSX.Element {
  return (
    <Providers>
      <AppRoutes />
    </Providers>
  );
}
