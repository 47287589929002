/**
 * @author Manish Parui
 */

import React, { useRef, Fragment } from "react";
import { Flex, Box, Input, useColorMode } from "@chakra-ui/react";
import { TfiUpload, TfiDownload } from "react-icons/tfi";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { IProps, ISheetNames, IWorksheets } from "./IExcel";

type IMap<T = boolean> = {
  [key: string]: T;
};

const Excel = ({
  onFileUpload,
  data,
  name,
  visibility = "both",
}: IProps): JSX.Element => {
  const { colorMode } = useColorMode();

  const fileInputRef = useRef<HTMLInputElement>(null);

  // open system dialog for file upload
  function handleUploadButtonClick(): void {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }
  // end open system dialog for file upload

  // read uploaded file
  function readFileContents(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file contents"));
        }
      };
      reader.onerror = (error) => {
        reject(new Error("Something went wrong"));
      };
      reader.readAsArrayBuffer(file);
    });
  }
  // end read uploaded file

  function resetInputFilesField() {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const generateRow = (dataRows: string[][], headers: string[]) => {
    const list = dataRows.map((row) => {
      const obj: IMap<string> = {};
      row.forEach((cellValue, index) => {
        const key = headers[index];
        obj[key] = cellValue;
      });
      return obj;
    });

    return list;
  };

  const generateSheetData = (workbook: XLSX.WorkBook): IMap<IMap<string>[]> => {
    const sheetData: IMap<IMap<string>[]> = {};

    workbook.SheetNames.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const rows: string[][] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });

      const headers = rows[0];
      const dataRows = rows.slice(1);
      const dataObjects = generateRow(dataRows, headers);
      sheetData[sheetName] = dataObjects;
    });

    return sheetData;
  };

  // upload handler
  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (!file) {
      resetInputFilesField();
      return;
    }

    const data = await readFileContents(file).catch(() => null);
    if (!data) {
      return;
    }
    const workbook = XLSX.read(data, { type: "array" });
    const sheetData = generateSheetData(workbook);

    resetInputFilesField();

    if (onFileUpload) {
      onFileUpload(sheetData);
    }
  }
  // end upload handler

  // download handler
  function handleFileDownload(): void {
    let sheetNames: ISheetNames = [];
    let worksheets: IWorksheets = {};

    // if excel data getter function porvided then get data from function else generate from data object
    if (data) {
      sheetNames = Object.keys(data);
      sheetNames.forEach((sheetName) => {
        const sheetData = data[sheetName];
        if (sheetData.length && Array.isArray(sheetData[0])) {
          worksheets[sheetName] = XLSX.utils.aoa_to_sheet(
            sheetData as unknown as any
          );
        } else {
          worksheets[sheetName] = XLSX.utils.json_to_sheet(sheetData);
        }
      });
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { ...worksheets },
      SheetNames: [...sheetNames],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob: Blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, `${name ?? "data"}.xlsx`);
  }
  // end download handler

  return (
    <Flex gap={3}>
      {(visibility === "upload" || visibility === "both") && (
        <Fragment>
          <Input
            type={"file"}
            ref={fileInputRef}
            display={"none"}
            accept={".xlsx, .xls"}
            onChange={handleFileUpload}
          />
          <Box
            padding={1}
            borderRadius={"md"}
            border={"1px solid"}
            borderColor={colorMode === "light" ? "black" : "white"}
            _hover={{
              cursor: "pointer",
            }}
            onClick={handleUploadButtonClick}
          >
            <TfiUpload />
          </Box>
        </Fragment>
      )}

      {(visibility === "download" || visibility === "both") && (
        <Box
          padding={1}
          borderRadius={"md"}
          border={"1px solid"}
          borderColor={colorMode === "light" ? "black" : "white"}
          _hover={{
            cursor: "pointer",
          }}
          onClick={handleFileDownload}
        >
          <TfiDownload />
        </Box>
      )}
    </Flex>
  );
};

export default Excel;
