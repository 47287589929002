import { Routes, Route, BrowserRouter } from "react-router-dom";
import AboutPage from "../pages/about/AboutPage";
import ContactPage from "../pages/contact/ContactPage";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import ProjectsPage from "../pages/projects/ProjectsPage";
import RandomPasswordGeneratorPage from "../pages/random-password-generator/RandomPasswordGeneratorPage";
import TicTacToePage from "../pages/tic-tac-toe/TicTacToePage";
import InvestmentPortfolioPage from "../pages/investment-portfolio/InvestmentPortfolioPage";
import RetirementCalculatorPage from "../pages/retirement-calculator/RetirementCalculatorPage";
import CalculatorPage from "../pages/calculator/CalculatorPage";
import Layout from "./../layouts/Layout";

export default function AppRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {/* namespace / */}
          <Route index element={<HomePage />} />
          <Route path={"about"} element={<AboutPage />} />
          <Route path={"contact"} element={<ContactPage />} />
          {/* end namespace / */}

          {/* namespace /projects */}
          <Route path={"projects"}>
            <Route index element={<ProjectsPage />} />
            <Route
              path={"random-password-generator"}
              element={<RandomPasswordGeneratorPage />}
            />
            <Route path={"tic-tac-toe"} element={<TicTacToePage />} />
            <Route
              path={"investment-portfolio"}
              element={<InvestmentPortfolioPage />}
            />
            <Route
              path={"retirement-calculator"}
              element={<RetirementCalculatorPage />}
            />
            <Route path={"calculator"} element={<CalculatorPage />} />
          </Route>
          {/* end namespace /projects */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
