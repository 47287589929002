import React from "react";
import { Flex, Box, Grid, GridItem, Button, Input } from "@chakra-ui/react";

const CalculatorPage = () => {
  const buttons = [
    {
      id: "AC",
      label: "AC",
      colSpan: 2,
      rowSpan: 1,
    },
    {
      id: "<-",
      label: "<-",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: "/",
      label: "/",
    },

    {
      id: "7",
      label: "7",
    },
    {
      id: "8",
      label: "8",
    },
    {
      id: "9",
      label: "9",
    },
    {
      id: "*",
      label: "*",
    },

    {
      id: "4",
      label: "4",
    },
    {
      id: "5",
      label: "5",
    },
    {
      id: "6",
      label: "6",
    },
    {
      id: "-",
      label: "-",
    },

    {
      id: "1",
      label: "1",
    },
    {
      id: "2",
      label: "2",
    },
    {
      id: "3",
      label: "3",
    },
    {
      id: "+",
      label: "+",
    },

    {
      id: "0",
      label: "0",
      colSpan: 2,
    },
    {
      id: "=",
      label: "=",
      colSpan: 2,
      rowSpan: 1,
    },
  ];
  return (
    <Flex justifyContent={"center"} width={"full"}>
      <Box>
        <Input backgroundColor={"teal"}></Input>

        <Grid
          // backgroundColor={"white"}
          marginTop={"0.5rem"}
          gap={"0.5rem"}
          gridAutoFlow={"row"}
          templateColumns={"repeat(4, 50px)"}
          templateRows={"repeat(5, 50px)"}
        >
          {buttons.map((button) => (
            <GridItem
              key={button.id}
              colSpan={button?.colSpan}
              rowSpan={button?.rowSpan}
              display={"flex"}
            >
              <Button width={"full"} height={"full"} colorScheme={"teal"}>
                {button.label}
              </Button>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Flex>
  );
};

export default CalculatorPage;
