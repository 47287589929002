import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";

export default function Layout(): JSX.Element {
  return (
    <Flex direction={"column"} fontSize={"18px"}>
      <Header />
      <Box width={"100%"} paddingX={6}>
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  );
}
